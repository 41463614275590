import { defineComponent, nextTick, onMounted, reactive, ref } from "vue";
import { showImg, showToast } from "@/utils/utils";
import { useRouter } from "vue-router";
import { promotionDetail, promotionrate } from "@/api/team";
import QRCode from "qrcode"; // 引入 qrcode 库来生成二维码

import html2canvas from "html2canvas"; // 引入 html2canvas 库

import { showLoading, hideLoading } from "@/utils/utils"; // 引入 loading 函数

export default defineComponent({
  name: "team",

  setup() {
    const todos = ref([]);
    const container = ref();
    const showEmailDialog = ref(false);
    const router = useRouter();
    const person = reactive({
      state: {
        age: 0,
        name: "weizhanzhan"
      },
      methods: {
        setAge: age => {
          person.state.age = age;
        }
      }
    }); // 从本地读取 userinfo 数据

    const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
    const inviteLink = ref(`http://localhost:3003/register?inviterCode=${userInfo.inviterCode || ""}`);
    const promotionData = reactive({
      todayCommission: 0,
      todayNewTeamCount: 0,
      totalCommission: 0,
      totalTeamCount: 0,
      yesterdayCommission: 0,
      todayTeamDeposit: 0,
      totalTeamDeposit: 0,
      Arate: 0,
      Brate: 0,
      inviterCode: "",
      userName: ""
    });
    const showQRDialog = ref(false); // 控制二维码弹框的显示状态

    const showInviteDialog = ref(false); // 控制邀请码弹框的显示状态
    // 获取推广概览数据

    const getPromotionDetail = async () => {
      try {
        showLoading(); // 显示 loading
        // 使用 Promise.all 并行调用两个接口

        const [response1, response2] = await Promise.all([promotionDetail(), promotionrate() // 第二个接口
        ]); // 检查两个接口的响应，并更新数据

        if (response1.code === 1 && response2.code === 1) {
          Object.assign(promotionData, { ...response1.data,
            ...response2.data // 合并 promotionrate 的数据

          });
        }
      } catch (error) {
        console.error("Failed to fetch promotion details:", error);
      } finally {
        hideLoading(); // 隐藏 loading
      }
    }; // 复制邀请链接


    const copyInviteLink = () => {
      const textToCopy = inviteLink.value;
      navigator.clipboard.writeText(textToCopy).then(() => {
        showToast("Invitation link copied to clipboard!");
      }).catch(err => {
        console.error("Failed to copy:", err);
      });
    };

    const generateQRCode = () => {
      const canvas = document.getElementById("qrcode-canvas");
      QRCode.toCanvas(canvas, `http://localhost:3003/register?inviterCode=${userInfo.inviterCode || ""}`, {
        width: 150
      }, function (error) {
        if (error) console.error(error);
        console.log("QR code generated!");
      });
    };

    const showQRCodeDialog = () => {
      showQRDialog.value = true;
      nextTick(() => {
        generateQRCode();
      });
    };

    const closeDialog = () => {
      const modal = document.querySelector(".qr-modal");
      const overlay = document.querySelector(".qr-modal-overlay"); // 添加淡出动画

      modal === null || modal === void 0 ? void 0 : modal.classList.add("fade-out");
      overlay === null || overlay === void 0 ? void 0 : overlay.classList.add("fade-out-overlay"); // 等待动画完成后再关闭弹框

      setTimeout(() => {
        showQRDialog.value = false;
      }, 300); // 与淡出动画时长匹配
    };

    const toDetail = path => {
      router.push(path);
    };

    const toDetail2 = platform => {
      const inviterCode = promotionData.inviterCode; // 邀请码

      const inviteLink = `http://localhost:3003/register?inviterCode=${inviterCode}`;
      let shareUrl = "";

      switch (platform) {
        case "telegram":
          shareUrl = `https://t.me/share/url?url=${encodeURIComponent(inviteLink)}&text=Join%20us!`;
          break;

        case "Facebook":
          shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(inviteLink)}`;
          break;

        case "Whatsapp":
          shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(inviteLink)}`;
          break;

        default:
          console.error("Unsupported platform:", platform);
          return;
      }

      window.location.href = shareUrl; // 跳转到分享链接
    };

    const cancelDialog = () => {
      showEmailDialog.value = false;
    };

    const confirmDialog = () => {
      showEmailDialog.value = false;
    }; // 生成邀请码二维码


    const generateInviteQRCode = () => {
      const canvas = document.getElementById("invite_qrcode");
      QRCode.toCanvas(canvas, `http://localhost:3003/register?inviterCode=${userInfo.inviterCode || ""}`, {
        width: 150
      }, function (error) {
        if (error) console.error(error);
        console.log("Invite QR code generated!");
      });
    };

    const saveInviteAsPoster = () => {
      const saveButton = document.querySelector(".save-button");
      const inviteElement = document.querySelector(".invite-modal2");

      if (saveButton) {
        saveButton.style.display = "none"; // 隐藏保存按钮，避免出现在截图中
      } // 将隐藏的 invite-modal2 显示出来


      inviteElement.style.display = "block";
      setTimeout(() => {
        html2canvas(inviteElement, {
          scale: 2,
          useCORS: true,
          backgroundColor: "#121212",
          windowWidth: document.body.scrollWidth,
          windowHeight: document.body.scrollHeight
        }).then(canvas => {
          const link = document.createElement("a");
          link.href = canvas.toDataURL("image/png", 1.0);
          link.download = "invite-poster.png";
          link.click(); // 恢复 Save 按钮的显示

          if (saveButton) {
            saveButton.style.display = "block";
          } // 截图完成后，重新将 invite-modal2 隐藏


          inviteElement.style.display = "none";
        });
      }, 100); // 延迟 100ms，确保渲染完成
    }; // 打开邀请码弹框


    const showYQDialog = () => {
      showInviteDialog.value = true;
      nextTick(() => {
        generateInviteQRCode();
      });
    }; // 关闭邀请码弹框


    const closeInviteDialog = () => {
      showInviteDialog.value = false;
    };

    onMounted(() => {
      promotionData.inviterCode = userInfo.inviterCode;
      promotionData.userName = userInfo.userName;
      getPromotionDetail();
      nextTick(() => {
        generateInviteQRCode();
      });
    });
    return {
      todos,
      container,
      person,
      showImg,
      toDetail,
      cancelDialog,
      confirmDialog,
      showEmailDialog,
      showQRDialog,
      showQRCodeDialog,
      closeDialog,
      showYQDialog,
      closeInviteDialog,
      showInviteDialog,
      saveInviteAsPoster,
      getPromotionDetail,
      promotionData,
      copyInviteLink,
      toDetail2
    };
  }

});