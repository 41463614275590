import { get, post } from "@/utils/request";
// 添加账户接口
export function saveAccount(params) {
    return post("/app/user/save", params);
}
// 推广概览数据接口
export function promotionDetail() {
    return get("/app/user/promotionDetail");
}
// 推广费率
export function promotionrate() {
    return get("/app/user/promotionrate");
}
// 团队成员接口
export function teamUsers(params) {
    // 使用 URLSearchParams 构建查询参数
    const queryParams = new URLSearchParams(params).toString();
    // 拼接 URL
    const url = `/app/user/teamUsers?${queryParams}`;
    // 发送 GET 请求
    return get(url);
}
